import React, { useEffect, useState } from "react";
import TextEditor from "../../ReactQuill/TextEditor";
import InputFields from "../../Forms/InputField";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";

function Home() {
  const [allData, setData] = useState({
    bannerHeading: null,
    bannerSubText: null,
    bannerVideo: null,
    newCustomerMonthly: null,
    newCustomerMonthlyValue: null,
    increaseInTheSize: null,
    increaseInTheSizeValue: null,
    yearOfExperience: null,
    yearOfExperienceValue: null,
    timeMoreRFQ: null,
    timeMoreRFQValue: null,
    imageResolutionOneUrl: null,
    imageResolutionOneUrlAltKey: null,
    imageResolutionTwoUrl: null,
    imageResolutionTwoUrlAltKey: null,
    imageResolutionThreeUrl: null,
    imageResolutionThreeUrlAltKey: null,
    seactionHeading: null,
    bannerSubTextSecond: null,
    satisfiedCustomer: null,
    satisfiedCustomerValue: null,
    textOne: null,
    textFiledTwo: null,
    textThree: null,
    alt_key: null,
  });

  const saveImage = (e, key) => {
    let type = ["image/gif", "image/jpeg", "image/png", "video/mp4"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Correct file type: png, jpeg, MP4");
        return;
      }
      if (type === "image/jpeg" || type === "image/png") {
        if (fileSize < 50 && fileSize < 2000) {
          alert("File size must be between 200KB and 2MB");
          return false;
        }
      }

      if (type === "video/mp4") {
        if (fileSize < 50 && fileSize < 5000) {
          alert("File size must be 5MB");
          return false;
        }
      }
    }
    UploadImage(file, key);
    return true;
  };

  const hanldeChange = (value, key) => {
    setData((pre) => ({ ...pre, [key]: value }));
  };

  const UploadImage = async (file, key) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    setData({ ...allData, [key]: data });
    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "home_page",
      section_name: key,
      content_value: allData[key],
    };

    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setData(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  /* Category Create  Funcation */
  const [getCategory, setGetCategory] = useState([]);

  const handleChangeCategory = (index, key, value) => {
    let temp = [...getCategory];
    temp[index][key] = value;
    setGetCategory(temp);
  };

  const handleCategoryUpdate = async (id, index) => {
    try {
      const PAYLOAD = {
        id: id,
        category_name: getCategory[index].category_name,
        description: getCategory[index].description,
      };
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMSCategory,
        method: "post",
        body: PAYLOAD,
      });
      setGlobalState({ loader: false });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      setGlobalState({ loader: false });
    }
  };

  const fatchCategory = async () => {
    try {
      const { data, message, success } = await ApiRequest({
        endUrl: API_END_URLS.getCategoryRecord,
        method: "get",
      });
      if (success) {
        setGetCategory(data);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: Home.jsx:178 ~ fatchCategory ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
    fatchCategory();
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="head-content mt-sm-0 mt-3">
            <p>Banner Heading</p>
          </div>
          <div class="sparess-textbox mt-3">
            <div class="row">
              <div class="col-md-9 col-lg-7">
                <h1>
                  <span class="green-textsparess">Fastest – Growing </span>
                  <span class="balck-textsparess">Aviation Marketplace</span>
                </h1>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <b>Text - Update</b>
            <TextEditor
              name="bannerHeading"
              onChange={(e) => hanldeChange(e, "bannerHeading")}
              value={allData?.bannerHeading}
            />
          </div>
          <div class="banner-uploadbtn">
            <button
              class="uploadBtn"
              onClick={() => handleUpdate("bannerHeading")}
            >
              Update{" "}
            </button>
          </div>

          <div className="right-contentbox pl-0">
            <div className="row mt-3">
              <div className="col-md-6 col-7">
                <div className="head-content">
                  <p>Banner Sub-Text</p>
                </div>
              </div>
            </div>
            <div className="sparess-textbox mt-3">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-left">
                    An online portal to list your products & services applied in
                    marine, aviation & land based needs to sale or rent or lease
                  </h3>
                </div>
              </div>
            </div>

            <section className="row mt-4">
              <div className="col-md-12">
                <div className="form-group">
                  <TextEditor
                    name="bannerSubText"
                    onChange={(e) => hanldeChange(e, "bannerSubText")}
                    value={allData?.bannerSubText}
                  />
                </div>
                <div className="banner-uploadbtn">
                  <button
                    className="uploadBtn"
                    onClick={() => handleUpdate("bannerSubText")}
                  >
                    Update
                  </button>
                </div>

                {/* Start  Video Code */}

                <div className="video-filed-content mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="position-relative">
                        <iframe
                          class="embed-responsive-item"
                          src={
                            allData?.bannerVideo != null
                              ? allData?.bannerVideo
                              : "images/sample-video.mp4"
                          }
                          title="video"
                        ></iframe>
                        <div class="upload-btn-wrapper new-updatebtn">
                          <button class="uploadBtn">
                            <img src="images/nav-camera.png" alt=""></img>
                          </button>
                          <input
                            name="bannerVideo"
                            type="file"
                            onChange={(e) => saveImage(e, "bannerVideo")}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Video ALT Key Start Code */}
                    <InputFields
                      placeholder={"Please Enter Alt Text"}
                      className={"mt-0"}
                      name="alt_key"
                      onChange={(e) => hanldeChange(e.target.value, "alt_key")}
                      value={allData?.alt_key}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("alt_key")}
                      >
                        Update
                      </button>
                    </div>
                    {/* Video ALT Key End Code */}

                    <div className="col-md-12">
                      <div className="banner-uploadbtn">
                        <button
                          className="uploadBtn"
                          onClick={() => handleUpdate("bannerVideo")}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End  Video Code */}

                <div className="row mt-3">
                  <div className="col-md-7 col-7">
                    <InputFields
                      placeholder={"New Customer Monthly"}
                      className={"mt-0"}
                      name="newCustomerMonthly"
                      onChange={(e) =>
                        hanldeChange(e.target.value, "newCustomerMonthly")
                      }
                      value={allData?.newCustomerMonthly}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("newCustomerMonthly")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5 col-5 pl-0 ">
                    <InputFields
                      placeholder={"20%"}
                      className={"mt-0"}
                      name={"newCustomerMonthlyValue"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "newCustomerMonthlyValue")
                      }
                      value={allData?.newCustomerMonthlyValue}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("newCustomerMonthlyValue")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-7 col-7">
                    <InputFields
                      placeholder={"Increase in the size our Parts ..."}
                      className={"mt-0"}
                      name={"increaseInTheSize"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "increaseInTheSize")
                      }
                      value={allData?.increaseInTheSize}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("increaseInTheSize")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5 col-5 pl-0 ">
                    <InputFields
                      placeholder={"15%"}
                      className={"mt-0"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "increaseInTheSizeValue")
                      }
                      value={allData?.increaseInTheSizeValue}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("increaseInTheSizeValue")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-7 col-7">
                    <InputFields
                      placeholder={"Years of experience as a market player"}
                      className={"mt-0"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "yearOfExperience")
                      }
                      name={"yearOfExperience"}
                      value={allData?.yearOfExperience}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("yearOfExperience")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5 col-5 pl-0 ">
                    <InputFields
                      placeholder={"+12"}
                      className={"mt-0"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "yearOfExperienceValue")
                      }
                      name={"yearOfExperienceValue"}
                      value={allData?.yearOfExperienceValue}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("yearOfExperienceValue")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-7 col-7">
                    <InputFields
                      placeholder={"Times more RFQs sent annually"}
                      className={"mt-0"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "timeMoreRFQ")
                      }
                      value={allData?.timeMoreRFQ}
                      name={"timeMoreRFQ"}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("timeMoreRFQ")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5 col-5 pl-0 ">
                    <InputFields
                      placeholder={"5x"}
                      className={"mt-0"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "timeMoreRFQValue")
                      }
                      value={allData?.timeMoreRFQValue}
                      name={"timeMoreRFQValue"}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("timeMoreRFQValue")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>

                {/*Category  Start Code  */}

                <div class="row mt-5">
                  <div class="col-md-3 col-12">
                    <div class="contentimg-box position-relative">
                      <img
                        src={
                          allData?.imageResolutionOneUrl != null
                            ? allData?.imageResolutionOneUrl
                            : "images/whoweare1.png"
                        }
                        alt=""
                      />
                      <div class="upload-btn-wrapper new-updatebtn">
                        <button class="uploadBtn">
                          <img src="images/nav-camera.png" alt=""></img>
                        </button>
                        <input
                          name="imageResolutionOneUrl"
                          type="file"
                          onChange={(e) =>
                            saveImage(e, "imageResolutionOneUrl")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* Image One ALT Key Start Code */}
                  <InputFields
                    placeholder={"Please Enter Alt Text"}
                    className={"mt-0"}
                    name="imageResolutionOneUrlAltKey"
                    onChange={(e) =>
                      hanldeChange(
                        e.target.value,
                        "imageResolutionOneUrlAltKey"
                      )
                    }
                    value={allData?.imageResolutionOneUrlAltKey}
                  />
                  <div className="banner-uploadbtn">
                    <button
                      className="uploadBtn"
                      onClick={() =>
                        handleUpdate("imageResolutionOneUrlAltKey")
                      }
                    >
                      Update
                    </button>
                  </div>

                  {/* Image ALT Key End Code */}

                  <div class="col-md-9 col-lg-4 pl-sm-0">
                    <div className="head-content mt-sm-0 mt-3">
                      <p>Image Resolution</p>
                    </div>
                    <span class="lenth-box align-items-center mt-3">
                      {" "}
                      <img src="images/question.png" alt="/" />
                      1440px * 840 px only
                    </span>
                    <div class="upload-btn-wrapper mt-3">
                      <button
                        class="uploadBtn"
                        onClick={() => handleUpdate("imageResolutionOneUrl")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>

                <div className="darker-input-field mt-4 mt-sm-5">
                  <div class="row">
                    {getCategory?.map((cat, index) => {
                      return (
                        <div className="col-md-4 mb-sm-0 mb-2">
                          <InputFields
                            placeholder="Sea"
                            className={"mt-0"}
                            name={"sea"}
                            value={cat?.category_name}
                            onChange={(e) =>
                              handleChangeCategory(
                                index,
                                "category_name",
                                e.target.value
                              )
                            }
                          />
                          <div className="banner-uploadbtn">
                            <buttonx
                              className="uploadBtn"
                              onClick={() =>
                                handleCategoryUpdate(cat?.id, index)
                              }
                            >
                              Update
                            </buttonx>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {getCategory?.map((cat, index) => {
                  return (
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="head-content">
                          <p>{cat?.category_name}</p>
                        </div>
                        <div className="darker-input-field mt-3">
                          <div class="row">
                            <div className="col-md-12">
                              <InputFields
                                placeholder={
                                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ..."
                                }
                                className={"mt-0"}
                                name={"seaDescription"}
                                onChange={(e) =>
                                  handleChangeCategory(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                                value={cat?.description}
                              />
                              <div className="banner-uploadbtn">
                                <button
                                  className="uploadBtn"
                                  onClick={() =>
                                    handleCategoryUpdate(cat?.id, index)
                                  }
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="head-content">
                      <p>Air</p>
                    </div>
                    <div className="darker-input-field mt-3">
                      <div class="row">
                        <div className="col-md-12">
                          <InputFields
                            placeholder={
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ..."
                            }
                            name={"airDescription"}
                            className={"mt-0"}
                            onChange={(e) =>
                              setCategory({
                                ...category,
                                airDescription: e.target.value,
                              })
                            }
                            value={category?.airDescription}
                          />
                          <div className="banner-uploadbtn">
                            <button
                              className="uploadBtn"
                              onClick={() => handleCategoryUpdate()}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="head-content">
                      <p>Land</p>
                    </div>
                    <div className="darker-input-field mt-3">
                      <div class="row">
                        <div className="col-md-12">
                          <InputFields
                            placeholder={
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ..."
                            }
                            className={"mt-0"}
                            name={"landDescription"}
                            onChange={(e) =>
                              setCategory({
                                ...category,
                                landDescription: e.target.value,
                              })
                            }
                            value={category?.landDescription}
                          />
                          <div className="banner-uploadbtn">
                            <button
                              className="uploadBtn"
                              onClick={() => handleCategoryUpdate()}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/*Category  End Code  */}

                <div className="row mt-3">
                  <div className="col-md-6 mb-sm-0 mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div class="contentimg-box position-relative">
                          <img
                            src={
                              allData?.imageResolutionTwoUrl != null
                                ? allData?.imageResolutionTwoUrl
                                : "images/whoweare1.png"
                            }
                            alt=""
                          />
                          <div class="upload-btn-wrapper new-updatebtn">
                            <button class="uploadBtn ">
                              <img src="images/nav-camera.png" alt=""></img>
                            </button>
                            <input
                              name="imageResolutionTwoUrl"
                              type="file"
                              onChange={(e) =>
                                saveImage(e, "imageResolutionTwoUrl")
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* Image ALT Key End Code */}

                      <div className="col-md-8">
                        <span class="lenth-box align-items-center mt-3">
                          <img src="images/question.png" alt="/" />
                          668px * 1226 px only
                        </span>
                        <div class="upload-btn-wrapper mt-3">
                          <button
                            class="uploadBtn"
                            onClick={() =>
                              handleUpdate("imageResolutionTwoUrl")
                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-sm-0 mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div class="contentimg-box position-relative">
                          <img
                            src={
                              allData?.imageResolutionThreeUrl != null
                                ? allData?.imageResolutionThreeUrl
                                : "images/whoweare1.png"
                            }
                            alt=""
                          />
                          <div class="upload-btn-wrapper new-updatebtn">
                            <button class="uploadBtn">
                              <img src="images/nav-camera.png" alt=""></img>
                            </button>
                            <input
                              name="imageResolutionThreeUrl"
                              type="file"
                              onChange={(e) =>
                                saveImage(e, "imageResolutionThreeUrl")
                              }
                            />
                          </div>
                        </div>

                        {/* Image ALT Key End Code */}
                      </div>
                      <div className="col-md-8">
                        <span class="lenth-box align-items-center mt-3">
                          <img src="images/question.png" alt="/" />
                          668px * 1226 px only
                        </span>
                        <div class="upload-btn-wrapper mt-3">
                          <button
                            class="uploadBtn"
                            onClick={() =>
                              handleUpdate("imageResolutionThreeUrl")
                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4 mt-sm-5">
                  <div class="col-md-12">
                    <div class="head-content">
                      <p>Section Heading</p>
                    </div>
                    <div class="sparess-textbox mt-3">
                      <div class="row">
                        <div class="col-md-9 col-lg-7">
                          <h1>
                            <span class="balck-textsparess">
                              Lorem Ipsum is simply dummy text of the printing
                            </span>
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <b>Text - Update</b>
                      <TextEditor
                        name={"seactionHeading"}
                        onChange={(e) => hanldeChange(e, "seactionHeading")}
                        value={allData?.seactionHeading}
                      />
                    </div>
                    <div class="banner-uploadbtn">
                      <button
                        class="uploadBtn"
                        onClick={() => handleUpdate("seactionHeading")}
                      >
                        Update{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="head-content">
                      <p>Banner Sub-Text</p>
                    </div>
                  </div>
                </div>
                <div className="sparess-textbox mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="text-left" style={{ color: "#000000" }}>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div class="form-group mt-3">
                      <b>Text - Update</b>
                      <TextEditor
                        name={"bannerSubTextSecond"}
                        onChange={(e) => hanldeChange(e, "bannerSubTextSecond")}
                        value={allData?.bannerSubTextSecond}
                      />
                    </div>
                    <div class="banner-uploadbtn">
                      <button
                        class="uploadBtn"
                        onClick={() => handleUpdate("bannerSubTextSecond")}
                      >
                        Update{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <InputFields
                      placeholder={"Satisfied Customers"}
                      className={"mt-0"}
                      name={"satisfiedCustomer"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "satisfiedCustomer")
                      }
                      value={allData?.satisfiedCustomer}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("satisfiedCustomer")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 mt-sm-0 mt-3">
                    <InputFields
                      placeholder={"200+"}
                      className={"mt-0"}
                      name={"satisfiedCustomerValue"}
                      onChange={(e) =>
                        hanldeChange(e.target.value, "satisfiedCustomerValue")
                      }
                      value={allData?.satisfiedCustomerValue}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("satisfiedCustomerValue")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-1 mt-2 col-2">
                    <img src="/images/green-check.svg" alt="/" />
                  </div>
                  <div className="col-md-11 pl-0 col-10">
                    <div className="darker-input-field">
                      <InputFields
                        placeholder={"Lorem Ipsum is a simply dummy text"}
                        className={"mt-0"}
                        name={"textOne"}
                        onChange={(e) =>
                          hanldeChange(e.target.value, "textOne")
                        }
                        value={allData?.textOne}
                      />
                    </div>
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("textOne")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-1 mt-2 col-2">
                    <img src="/images/green-check.svg" alt="/" />
                  </div>
                  <div className="col-md-11 pl-0 col-10">
                    <div className="darker-input-field">
                      <InputFields
                        placeholder={"Lorem Ipsum is a simply dummy text"}
                        className={"mt-0"}
                        name={"textTwo"}
                        onChange={(e) =>
                          hanldeChange(e.target.value, "textTwo")
                        }
                        value={allData?.textTwo}
                      />
                    </div>
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("textTwo")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-1 mt-2 col-2">
                    <img src="/images/green-check.svg" alt="/" />
                  </div>
                  <div className="col-md-11 pl-0 col-10">
                    <div className="darker-input-field">
                      <InputFields
                        placeholder={"Lorem Ipsum is a simply dummy text"}
                        className={"mt-0"}
                        name={"textThree"}
                        onChange={(e) =>
                          hanldeChange(e.target.value, "textThree")
                        }
                        value={allData?.textThree}
                      />
                    </div>
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() => handleUpdate("textThree")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
