export const API_END_URLS = {
    login: "v1/admin/login",
    register: "v1/admin/register",


    /* Users Route */
    users: "v1/admin/users",
    usersDashboard: "v1/admin/dashboard",
    userGraph : "v1/admin/user-graph",
    userDetailView: "v1/admin/user",
    userUpdate: "v1/admin/user",
    userGet : "v1/admin/user",
    editAdmin : "v1/admin/user",
    userGlobally : "v1/admin/UsersGlobally",

    blogCategories: "v1/admin/blog-categories",
    blogCategoryCreate: "v1/admin/blog-category",
    blogCategoryEdit: "v1/admin/blog-category",

    productCategories: "v1/admin/product-categories",
    productCategoryCreate: "v1/admin/product-category-create",
    productCategoryEdit: "v1/admin/product-category",
    productParentCategories: "v1/admin/product-categories/parent",
    ProductCategoryListing: "v1/admin/product-category",
    ProductCategoryView : "v1/admin/product-category-view",
    ProductCategoryUpdate : "v1/admin/product-category",
    ProductBluckUpload: "v1/admin/upload",
    ProductSmapleFileDownload: "/v1/admin/sample-file-download",

    productSubCategoryCreate : "v1/admin/product-subCategory/create",
    productSubCategories : "v1/admin/product-subCategories",
    productSubCategoryEdit : "v1/admin/product-subCategory",
    productSubCategoryUpdate : "v1/admin/product-subCategory",
    productSubCategoryDelete : "v1/admin/product-subCategory",


    serviceCategories: "v1/admin/service-categories",
    serviceCategoryCreate: "v1/admin/service-category",
    serviceCategoryEdit: "v1/admin/service-category",
    serviceParentCategories: "v1/admin/service-categories/parent",

    subscriptionPlans: "v1/admin/subscription-plans",
    subscriptionPlanCreate: "v1/admin/subscription-plan",
    subscriptionPlanEdit: "v1/admin/subscription-plan",
    subscriptionPlanUpdate : "v1/admin/subscription-plan",
    subscriptionAcceptance : "v1/admin/subscription-acceptance",
    subscriptionAcceptanceExport : "/v1/admin/subscriptionAcceptanceDataExport",


    brands: "v1/admin/brands",
    brandCreate: "v1/admin/brand",
    brandEdit: "v1/admin/brand",

    getContactUsList: "v1/admin/contact-us-queries",
    getContactUsView: "v1/admin/contact-us-query",
    updateContactUs: "v1/admin/contact-us-query",
    
    getHelpDeskList: "v1/admin/helpdesk-queries",
    getHelpDeskView: "v1/admin/helpdesk-query",
    updateHelpDesk : "v1/admin/helpdesk-query",

    /* CMS END URLS */
    createWhatWeAre:"v1/admin/create-cms-what-we-offer", 
    getDataWhatWeAre : "v1/admin/get-who-we-are",
    createCMS: "v1/admin/create-cms-home",
    createCMSCategory : "v1/admin/category-create",
    getCategoryRecord : "v1/admin/all-categories",
    fileUpload: "v1/admin/upload-image",
    getCMSRecord: "v1/admin/getCMSHome",
    // S3Bucket: "https://s3bucketstaqo.s3.ap-south-1.amazonaws.com/marine/",
    S3Bucket : "https://navigatorsrus-s3.s3.ap-south-1.amazonaws.com/navigatorsrus/",
    // createCMSWhoWeAre: "v1/admin/create-cms-whoWeAre",
    // getCMSWhoWeAre: "v1/admin/get-cms-whoWeAre",

    createSubscription: "v1/admin/create-cms-subscription",
    getSubscription: "v1/admin/get-cms-subscription",
    getTransactionHistory : "v1/admin/transaction-history",
    transactionGraph: "v1/admin/transaction-graph",

    /* CMS Blogs */
    blogTypes : "v1/admin/blog-types",
    createBlog: "v1/admin/create-cms-blog",
    getBlog: "v1/admin/get-cms-blogs",
    editBlog: "v1/admin/cms-blog",
    updateblog: "v1/admin/cms-blog",
    deleteBlog: "v1/admin/cms-blog/",

    /* CMS HelpDesk */
    createHelpDesk: "v1/admin/create-cms-helpdesk",
    getHelpDesk: "v1/admin/cms-helpdesk",

    /* CMS Footer */
    createFooter: "v1/admin/create-cms-footer",
    getFooters: "v1/admin/cms-footers",

    /* Review & Rating Listing */
    getReviews: "v1/admin/reviews",    
    reviewEdit : "v1/admin/review",
    reviewUpdate : "v1/admin/review-update",
    reviewPublish : "v1/admin/review-publish",

    /* Seo Urls */
    createSeo : "v1/admin/create-seo",
    seoListing : "v1/admin/seo-list",
    seoDelete : "v1/admin/seo-delete",
    seoView: "v1/admin/seo-view",
    seoUpdate: "v1/admin/seo-update"
}