import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import moment from "moment";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Header() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [user, setUser] = useState({});

  const getAdmin = async (id) => {
    const { data, message, success } = await ApiRequest({
      endUrl: API_END_URLS.userGet,
      method: "get",
    });
    if (success) {
      setUser({ ...data });
    } else {
      showMessage(message);
    }
  };
  const [shows, setShows] = useState(false);

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  /* Logout */
  const hanldeLogOut = () => {
    if (window.confirm("Are you sure you want logout")) {
      localStorage.clear();
      navigate("/Login");
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <>
      <header class="d-none d-sm-block">
        <div class="container-fluid">
          <div class="tophead">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="search-head">
                  <input className="form-control" type="text" />
                  <img src="/images/serch-icon-head.svg" alt="/" />
                </div>
              </div>
              <div className="col-md-7">
                <ul className="list-unstyled tophead-right">
                  <li>
                    <div className="login-button ">
                      <button className="btn btn-admin">
                        Login as Administrator
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="notify-bell position-relative">
                      <Link to="/">
                        <img
                          src="/images/bell.svg"
                          class="bell-img"
                          alt="/"
                          onClick={handleShows}
                        />
                        <span class="dots">4</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="user-profile d-flex align-items-center">
                      <p className="pr-3">{user?.name}!</p>
                      <Dropdown className="user-drop user-dropdown">
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="userpic-btn"
                        >
                          <div className="new-usermainbox border-0">
                            {/* <img src='/images/navigator-us-icon.svg' alt='/' /> */}
                            <img
                              src={API_END_URLS.S3Bucket + user.profile_url}
                              class="pro-headimg"
                              alt="Images"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-drop">
                          <Dropdown.Item href="/user">
                            <img src="/images/drop-user.png" alt="" />
                            View Profile
                          </Dropdown.Item>
                          <Dropdown.Item onClick={hanldeLogOut}>
                            {" "}
                            <img
                              src="/images/drop-lock.png"
                              alt=""
                            /> Logout{" "}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="row align-items-center">
                            <div class="col-md-6 col-5 moblogo">
                                <h1> Hello {user?.name}! </h1>
                                <p> <img src="/images/calender.svg" alt='' /> {moment(user?.createdAt).format('DD-MMM-YYYY')}</p>
                            </div>
                            <div class="col-md-6 col-7 pr-0">
                                <ul class="tophead-right">
                                    <li className="mr-4" >
                                        <Link to="/">
                                            <img src="/images/bell.png" class="bell-img" alt='' /> <span class="dots"></span> </Link> </li>
                                    <li class="client-loginHead ">
                                        <Link>Logged in as <span>{user?.type}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Dropdown className="user-drop user-dropdown ">
                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="userpic-btn" >
                                                <div className="new-usermainbox border-0">
                                                    <img src='images/navigator-us-icon.svg' alt='/' />
                                                    <img src={API_END_URLS.S3Bucket + user.profile_url} class="pro-headimg" alt="" />
                                                </div>

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="user-drop" >
                                                <Dropdown.Item href="/user"> <img src="images/drop-user.svg" alt='' /> View Profile</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={hanldeLogOut}
                                                > <img src="images/drop-lock.svg" alt='' /> Logout </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
          </div>
        </div>
      </header>

      <header class="d-block d-sm-none">
        <div class="tophead">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-7 pr-0 pl-1 d-flex">
                <img
                  src="/images/menu-left-alt.svg"
                  class="mr-1 menu-btn-left"
                  alt=""
                  onClick={handleShow}
                />
                <Link to="/">
                  <img
                    src="/images/black-logo.png"
                    class="logo-mobile"
                    alt=""
                  />{" "}
                </Link>
              </div>
              <div class="col-5 pr-0">
                {/* <Link to="/Login"><button type="button" class="btn  login-btn mr-2">Login</button></Link> 
                            <Link to="/"> <button type="button" class="btn  login-btn mr-2">Register</button></Link>
                            <Link to="/"> <i class="fa-regular fa-heart" ></i> </Link> */}

                <ul class="tophead-right">
                  <li>
                    <Link to="##">
                      <img
                        src="/images/bell.svg"
                        class="bell-img"
                        alt=""
                        onClick={handleShows}
                      />
                      <span class="dots">4</span>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="#">
                      {" "}
                      <img
                        src="/images/user-pic.png"
                        class="pro-headimg"
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-dialog-slideout home-pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="/images/nav-logo.png" alt="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <div class="row">
            <div class="col-md-12">
              <div class="home-popcontent">
                <ul>
                  <li>
                    <div className="d-flex">
                      <div className="login-button">
                        <button className="btn btn-admin">
                          Login as Administrator
                        </button>
                      </div>

                      {/* <div className='login-button'>
                                                <button className='btn btn-admin d-flex align-items-center'>
                                                    <label class="switch">
                                                        <input type="checkbox" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <span className='slider-round-text'>In Active</span>
                                                </button>
                                            </div> */}
                    </div>
                  </li>
                  <li></li>
                  <li>
                    <a href="/">
                      <img src="/images/dash-icon.png" alt="/" /> Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="/cost">
                      <img src="/images/cost-manage-icon.png" alt="/" /> Cost
                      Manager
                    </a>
                  </li>
                  <li>
                    <a href="/categories/product-categories">
                      <img src="/images/cate-icon.png" alt="/" /> Category
                      Manager
                    </a>
                  </li>
                  <li>
                    <a href="/reviews">
                      <img src="/images/feedback-icon.png" alt="/" /> Feedback
                      Manager
                    </a>
                  </li>
                  <li>
                    <a href="/content">
                      <img src="/images/content-manage-icon.png" alt="/" />{" "}
                      Content Manager
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button type="button" class="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" class="btn btn-login">SELECT DATE & TIME</button> */}
          {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
          {/* <Button variant="primary" onClick={handleClose}>Save Changes</Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        className="push-notification-pop"
        show={shows}
        onHide={handleCloses}
      >
        <Modal.Header>
          <div className="push-headtext">
            <h1>Notification</h1>
            <h5>
              <img src="/images/push-tick-img.svg" alt="/" /> Mark all as read
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="push-tabs-pop">
            <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
              <Tab
                eventKey="all"
                title={
                  <span>
                    All <span className="badge">5</span>
                  </span>
                }
              >
                {" "}
                <div className="push-area">
                  <ul className="list-unstyled push-area-list">
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon1.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulation ! You are now registered standered
                              Monthly Subscription in NavigatorsRus.
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2h ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon2.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Dear User new blog has been added to
                              NavigatorsRus. To know More
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>4h ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> New Blog Post </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="d-flex">
                            <a href="##">
                              <button className="pushed-btn pushed-btn-grn">
                                New Blog
                              </button>
                            </a>
                            <a href="##">
                              <button className="pushed-btn pushed-btn-lgt">
                                All Blog
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Attention ! Your Promotion Posting Display Period
                              will exp. within 24Hr. to post it again Please log
                              on to your Account in NavaigatorsRus
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>1 day ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> Upgrade Plan </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>3 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab eventKey="read" title="Read">
                <div className="push-area">
                  <ul className="list-unstyled push-area-list">
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon1.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulation ! You are now registered standered
                              Monthly Subscription in NavigatorsRus.
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2h ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon2.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Dear User new blog has been added to
                              NavigatorsRus. To know More
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>4h ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> New Blog Post </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="d-flex">
                            <a href="##">
                              <button className="pushed-btn pushed-btn-grn">
                                New Blog
                              </button>
                            </a>
                            <a href="##">
                              <button className="pushed-btn pushed-btn-lgt">
                                All Blog
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Attention ! Your Promotion Posting Display Period
                              will exp. within 24Hr. to post it again Please log
                              on to your Account in NavaigatorsRus
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>1 day ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> Upgrade Plan </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>3 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab eventKey="unread" title="Unread">
                <div className="push-area">
                  <ul className="list-unstyled push-area-list">
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon1.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulation ! You are now registered standered
                              Monthly Subscription in NavigatorsRus.
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2h ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon2.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Dear User new blog has been added to
                              NavigatorsRus. To know More
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>4h ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> New Blog Post </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="d-flex">
                            <a href="##">
                              <button className="pushed-btn pushed-btn-grn">
                                New Blog
                              </button>
                            </a>
                            <a href="##">
                              <button className="pushed-btn pushed-btn-lgt">
                                All Blog
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Attention ! Your Promotion Posting Display Period
                              will exp. within 24Hr. to post it again Please log
                              on to your Account in NavaigatorsRus
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>1 day ago</p>
                              <ul className="m-0">
                                <li>
                                  <p> Upgrade Plan </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>2 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="unread-list">
                      <div className="row">
                        <div className="col-md-2 col-3">
                          <div className="push-icon">
                            <img src="/images/push-icon3.png" alt="/" />
                          </div>
                        </div>
                        <div className="col-md-10 col-9 pl-0">
                          <div className="push-context">
                            <h3>
                              Congratulations! You have successfully added a new
                              posting on NavigatorsRus, Wish you Success! The
                              posting will be displayed from now until xxxx date
                              and xx:xx hrs GMT
                            </h3>
                            <div className="d-flex align-items-center">
                              <p>3 day ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
